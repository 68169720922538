<template>
  <div class="friend-list-tab mt-2">
    <div class="tab-content">
      <div class="iq-card-body p-0">
        <!-- <h4>Admins and moderators</h4> -->
        <h4>{{ $t("Club.5cb@administrators_and_m") }}</h4>
        <hr />
        <div class="row">
          <div
            class="col-md-6 col-lg-6 col-12 mb-3"
            v-for="(friend, index1) in adminList"
            :key="index1"
          >
            <div class="member-list">
              <div class="img_box">
                <img :src="friend.avatar" />
              </div>

              <div class="friend_other ml-3">
                <div
                  v-if="userType != 1"
                  class="iq-card-post-toolbar d-flex justify-content-end"
                >
                  <b-dropdown
                    v-if="friend.status!=3"
                    id="dropdownMenuButton40"
                    right
                    variant="none"
                    menu-class="p-0"
                  >
                    <template v-slot:button-content>
                      <i class="ri-more-line"></i>
                    </template>
                    <b-dropdown-item
                      class="dropdown-item p-0"
                      @click="setOrRemovalAdmin(friend, 1)"
                    >
                      <h6>{{ $t("Club.0eb@removal") }}</h6>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="transferLeader(friend)"
                      class="dropdown-item p-0"
                    >
                      <h6>Set as owner</h6>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div class="height_36" v-else></div>
                </div>
                <div :class="{ 'mt-4': userType == 1 }">
                  <h5>{{ friend.nick_name }}</h5>
                  <div
                    class="
                      iq-card-header-toolbar
                      d-flex
                      align-items-center
                      justify-content-end
                    "
                    @click="friendRequest(friend)"
                    v-if="friend.user_id != localUserId"
                  >
                    <b-btn
                      v-if="friend.is_friend"
                      class="btn btn-secondary mr-2"
                    >
                      <i class="ri-check-line mr-1 text-white font-size-16"></i>
                      {{ $t("Club.233@friend") }}
                    </b-btn>
                    <span v-else class="btn btn-primary  mr-2" style="background:#fff;color:#50b5ff;border:1px solid #50b5ff">
                      <i class="ri-add-line mr-1 font-size-16"></i>
                      {{ $t("Club.233@friend") }}
                    </span>
                  </div>
                  <div v-else class="height_38"></div>
                </div>
                <p>{{ friend.organization }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="iq-card-body p-0">
        <!-- <h4>Other members</h4> -->
        <h4>{{ $t("Club.a3f@other_members") }}</h4>
        <hr />
        <div class="row">
          <div
            class="col-md-6 col-lg-6 mb-3"
            v-for="(friend, index1) in nomalMemberList"
            :key="index1"
          >
            <div class="member-list">
              <div class="img_box">
                <img :src="friend.avatar" />
              </div>

              <div class="friend_other ml-3">
                <div
                  v-if="userType != 1"
                  class="iq-card-post-toolbar d-flex justify-content-end"
                >
                  <b-dropdown
                    id="dropdownMenuButton40"
                    right
                    variant="none"
                    menu-class="p-0"
                  >
                    <template v-slot:button-content>
                      <i class="ri-more-line"></i>
                    </template>
                    <b-dropdown-item
                      class="dropdown-item p-0"
                      @click="setOrRemovalAdmin(friend, 2)"
                    >
                      <h6>{{ $t("Club.049@set_as_administrator") }}</h6>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="kickOutGroup(friend)"
                      class="dropdown-item p-0"
                    >
                      <h6>Remove</h6>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div :class="{ 'mt-4': userType == 1 }">
                  <h5>{{ friend.nick_name }}</h5>
                  <div
                    class="
                      iq-card-header-toolbar
                      d-flex
                      align-items-center
                      justify-content-end
                    "
                    @click="friendRequest(friend)"
                    v-if="friend.user_id != localUserId"
                  >
                    <span
                      v-if="friend.is_friend"
                      class="btn btn-secondary mr-2"
                    >
                      <i class="ri-check-line mr-1 text-white font-size-16"></i>
                      Friend
                    </span>
                    <span v-else class="btn btn-primary mr-2" style="background:#fff;color:#50b5ff;border:1px solid #50b5ff">
                      <i class="ri-add-line mr-1 font-size-16"></i>
                      Friend
                    </span>
                  </div>
                  <div v-else class="height_38"></div>
                  <p>{{ friend.organization }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FriendTab",

  components: {},
  data() {
    return {
      nomalMemberList: [],
      adminList: [],
    };
  },
  created() {
    this.getGroupMember();
  },

  computed: {
    ...mapState({
      userType: (state) => state.Group.userType,
    }),

    localUserId() {
      return JSON.parse(localStorage.getItem("userInfo")).user_id;
    },
  },

  methods: {
    getGroupMember() {
      this.$http
        .getGroupMember({
          group_id: this.$route.query.id,
        })
        .then((res) => {
          this.nomalMemberList = res.data.filter((item) => item.status == 1);
          this.adminList = res.data.filter((item) => item.status != 1);
        });
    },
    // 剔除俱乐部
    kickOutGroup(friend) {
      this.$http
        .kickOutGroup({
          group_id: this.$route.query.id,
          user_id: friend.user_id,
        })
        .then((res) => {
          // console.log(res);
          if(res.status==200){
            this.getGroupMember();
          }
        });
    },
    // 撤销管理员 // 晋升为管理员
    setOrRemovalAdmin(friend, adminStatus) {
      // console.log(friend);
      this.$http
        .updateGroupAdmin({
          group_id: this.$route.query.id,
          user_id: friend.user_id,
          admin_status: adminStatus,
        })
        .then((res) => {
          if(res.status==200){
            this.getGroupMember();
          }
        });
    },
    // 设为俱乐部拥有者
    transferLeader(friend) {
      this.$http
        .transferLeader({
          user_id: friend.user_id,
          group_id: this.$route.query.id,
        })
        .then((res) => {
          if(res.status==200){
            this.getGroupMember();
          }
        });
    },
    // 申请好友
    friendRequest(friend) {
      if (!friend.is_friend) {
        this.$http
          .friendRequest({
            user_id: friend.user_id,
          })
          .then((res) => {
            console.log(res);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width:600px){
.member-list {
  border: 1px solid #ccc;
  display: flex;

  > .img_box {
    width: 150px;
    height: 150px;
    overflow: hidden;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .friend_other {
    width: calc(100% - 150px);
    .height_36{
      height: 36px;
    }
    .height_38{
      height: 38px;
    }
  }
}
}
@media (max-width:600px){
.member-list {
  border: 1px solid #ccc;
  display: flex;

  > .img_box {
    width: 150px;
    height: 100px;
    overflow: hidden;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .friend_other {
    width: 100%;
    .height_36{
      height: 20px;
    }
    .height_38{
      height: 20px;
    }
    h5{
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
    }
    p{
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
    }
  }
}
}

</style>
