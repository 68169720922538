<template>
  <div class="iq-card">
    <div class="iq-card-body">
      <div class="About p-2">
        <!-- 第一段 -->
        <h4>{{ $t("Club.58e@introduction") }}</h4>

        <div class="row">
          <div class="col-3 mt-2 infoTitle">
            <!-- <h6>
              <i class="ri-lock-unlock-line iconSize"></i>
              {{$t('Club.b23@visibility')}}
            </h6> -->
            Permission
          </div>
          <div class="col-9 mt-2 infoVal">
            <p class="mb-0">
              <!-- {{ aboutInfo.visibility == 1 ? $t('Club.aa1@private') : $t('Club.c8e@public') }} -->
              {{
                aboutInfo.visibility == 1 ? "intimate" : $t("Club.c8e@public")
              }}
            </p>
          </div>
          <div class="col-3 mt-2 infoTitle">
            <!-- <h6>
              <i class="ri-map-pin-line iconSize"></i>
              {{$t('Club.7ac@group_location')}}
            </h6> -->
            Country
          </div>
          <div class="col-9 mt-2 infoVal">
            <p class="mb-0">{{ aboutInfo.country | filterUnknown }}</p>
          </div>
          <div class="col-3 mt-2 infoTitle">
            <!-- <h6>
              <i class="ri-time-line iconSize"></i>
              {{$t('Club.2b7@date_created')}}
            </h6> -->
            CreateDate
          </div>
          <div class="col-9 mt-2 infoVal">
            <p class="mb-0">{{ aboutInfo.created_at | filterDate }}</p>
          </div>
        </div>
        <div
          class="mt-4"
          style="
            background: #f5fbff;
            color: #50b5ff;
            padding: 10px;
            border-radius: 5px;
          "
        >
          <p v-if="aboutInfo.introduce" v-html="aboutInfo.introduce"></p>
          <p v-else style="text-align: center">——Empty——</p>
        </div>
        <hr />
        <!-- 第二段 -->
        <!-- <h4 class="mt-3">{{ $t("Club.e07@club_rules") }}</h4> -->
        <h4 class="mt-3">Rules</h4>
        <div
          class="mt-2"
          style="
            background: #f5fbff;
            color: #50b5ff;
            padding: 10px;
            border-radius: 5px;
          "
        >
          <p v-if="aboutInfo.rules" v-html="aboutInfo.rules"></p>
          <p v-else style="text-align: center">——Empty——</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",

  components: {},
  filters: {
    filterDate(val) {
      let arr = val.split("-");
      if (arr[1] == "01" || arr[1] == "1") {
        return `January ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "02" || arr[1] == "2") {
        return `February ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "03" || arr[1] == "3") {
        return `March ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "04" || arr[1] == "4") {
        return `April ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "05" || arr[1] == "5") {
        return `May ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "06" || arr[1] == "6") {
        return `June ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "07" || arr[1] == "6") {
        return `July ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "08" || arr[1] == "8") {
        return `August ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "09" || arr[1] == "9") {
        return `September ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "10") {
        return `October ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "11") {
        return `November ${arr[2]}. ${arr[0]}.`;
      } else if (arr[1] == "12") {
        return `December ${arr[2]}. ${arr[0]}.`;
      }
    },
  },
  props: {
    aboutInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.About {
  ::v-deep img{
    max-width: 100%;
  }
  .iconSize {
    font-size: 16px;
    margin-right: 10px;
  }
}
.infoTitle {
  color: #333;
  font-size: 14px;
}
.infoVal {
  color: #999;
  font-size: 14px;
}
</style>
