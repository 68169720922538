<template>
  <section style="min-height: 95vh; overflow-y: auto;overflow-x: hidden" v-infinite-scroll="InfinitePostData"
    infinite-scroll-disabled="postInfiniteDisabled" infinite-scroll-delay="500" infinite-scroll-immediate="true"
    infinite-scroll-distance="0" class="profile_container_common" ref="profile">
    <b-row class="GroupDetail">
      <b-col sm="12">
        <iq-card class="iq-card">
          <div class="cover-container">
            <!--  -->
            <img :src="
                groupBaseInfo.background
                  ? groupBaseInfo.background
                  : '@/assets/images/page-img/08.jpg'
              " alt="profile-bg" class="rounded img-fluid" />
          </div>

          <!-- icon -->
          <!-- 如果用戶是管理才顯示修改圖標 -->
          <b-row v-if="userType == 3 || userType == 2">
            <b-col sm="12" class="d-flex justify-content-end">
              <ul class="profile-icon-box d-flex flex-wrap justify-end p-0 m-0">
                <!--  -->
                <!-- <li>
                  <el-upload
                    class="avatar-uploader"
                    action="no"
                    :http-request="imgUpload1"
                    :show-file-list="false"
                  >
                    <a><i class="ri-pencil-line"></i></a>
                  </el-upload>
                </li> -->
                <li @click="queryGroupInfo">
                  <a><i class="ri-settings-4-line"></i></a>
                </li>
              </ul>
            </b-col>
          </b-row>

          <b-row class="space-box">
            <!-- avatar -->
            <b-col sm="12" md="12" lg="8">
              <div class="user-info">
                <div class="avatar-box">
                  <img :src="groupBaseInfo.headimg" />
                </div>
                <div class="flow-data mt-md-3">
                  <h3 class="title" style="
                      width: 400px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    " :title="groupBaseInfo.group_name">
                    {{ groupBaseInfo.group_name }}
                  </h3>
                  <div class="d-flex align-items-center">
                    <i class="mr-1 iconSize" :class="
                        groupBaseInfo.visibility == 1
                          ? 'ri-lock-line'
                          : 'ri-lock-unlock-line'
                      "></i>
                    <!-- {{ groupBaseInfo.visibility == 1 ? "Private" : "Public" }} -->
                    {{
                    groupBaseInfo.visibility == 1
                    ? $t("Club.580@private_group")
                    : $t("Club.999@public_group")
                    }}
                  </div>
                </div>
              </div>
            </b-col>
            <!-- button -->
            <b-col sm="12" md="12" lg="4" class="btns">
              <!-- <div class="mb-1 col-md-6"> -->
              <div class="d-flex justify-content-end mt-md-3">
                <!-- 小头像 -->
                <div class="iq-media-group d-flex align-items-center">
                  <a v-for="(item, index) in groupBaseInfo.memberimg" :key="index" href="#" class="iq-media">
                    <img class="img-fluid avatar-40 rounded-circle" :src="item" alt="" />
                  </a>
                </div>
                <div class="ml-3 pl-1 mr-3 d-flex align-items-center">
                  <b-button @click="joinGroup(groupBaseInfo)" variant="primary">{{
                    groupBaseInfo.user_type | userTypeFilter
                    }}</b-button>
                </div>
              </div>
              <!-- </div> -->
            </b-col>
          </b-row>
        </iq-card>
        <iq-card class="w-100 p-0 tabsCard">
          <tab-nav :tabs="true" class="row mb-0 navTabs">
            <tab-nav-items :active="true" id="timeline-tab" ariaControls="timeline" role="tab" :ariaSelected="true"
              :title="$t('Club.5ba@timeline')" class="col-md-3 col-3 text-center mt-3" />
            <!-- About -->
            <tab-nav-items :active="false" id="about-tab" ariaControls="about" role="tab" :ariaSelected="false"
              :title="$t('Club.1c3@about')" class="col-md-3 col-3 text-center mt-3" />
            <!-- Members -->
            <tab-nav-items :active="false" id="members-tab" ariaControls="members" role="tab" :ariaSelected="false"
              :title="$t('Club.95c@member')" class="col-md-3 col-3 text-center mt-3" />
            <!-- Media -->
            <tab-nav-items :active="false" id="media-tab" ariaControls="media" role="tab" @click.native="clickMedia"
              :ariaSelected="false" :title="$t('Club.435@media')" class="col-md-3 col-3 text-center mt-3" />
          </tab-nav>
        </iq-card>
      </b-col>

      <b-col sm="12">
        <tab-content>
          <tab-content-item :active="true" id="timeline" aria-labelled-by="timeline-tab">
            <div v-if="userType == 0" class="iq-card">
              <div class="iq-card-body">
                <Private :visibility="groupBaseInfo.visibility" />
              </div>
            </div>
            <Timeline v-else ref="timeLine" @fatherMethod='postInfiniteDisabled=true' />
          </tab-content-item>
          <tab-content-item :active="false" id="about" aria-labelled-by="about-tab">
            <About :aboutInfo="groupBaseInfo" />
          </tab-content-item>
          <tab-content-item :active="false" id="members" aria-labelled-by="members-tab">
            <div class="iq-card">
              <div class="iq-card-body">
                <Private v-if="userType == 0" :visibility="groupBaseInfo.visibility" />
                <div v-else>
                  <Members />
                </div>
              </div>
            </div>
          </tab-content-item>
          <tab-content-item :active="false" id="media" aria-labelled-by="media-tab">
            <iq-card>
              <template v-slot:body>
                <Private v-if="userType == 0" :visibility="groupBaseInfo.visibility" />
                <Media ref="media" v-else />
              </template>
            </iq-card>
          </tab-content-item>
        </tab-content>
      </b-col>

      <!-- 邀请朋友的弹框 -->
      <b-modal id="addModifyPopup" centered :title="$t('Club.658@invite')" hide-footer>
        <div class="row h-100">
          <div class="col-5 contentLeft border-right">
            <!-- 搜索框 -->
            <div class="mb-3 d-flex flex-grow-1 searchBox">
              <el-input type="text" suffix-icon="el-icon-search" clearable v-model="searchUser"
                @change="getUserFriend()" :placeholder="$t('Club.b91@search_group')" />
            </div>
            <!-- 好友列表 -->
            <ul class="p-0">
              <li class="d-flex justify-content-between mb-3" v-for="(item, index) in friendsList" :key="index">
                <div class="d-flex align-items-center">
                  <div class="imgBox">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="name textOverflow">{{ item.nick_name }}</div>
                </div>
                <div class="d-flex align-items-center">
                  <input type="checkbox" class="form-check-input mr-3 mb-1" v-model="item.select"
                    @click="addUserList(item)" />
                  <!-- :disabled="item.lock" -->
                </div>
              </li>
            </ul>
          </div>
          <div class="col-7 d-flex flex-column justify-content-between contentRight">
            <div>
              <div class="title">{{ $t("Club.f46@please_check_the_con") }}.</div>

              <!-- 选中的添加好友 -->
              <ul class="p-0">
                <li class="mb-3 d-flex justify-content-between" v-for="(item, i) in filterSelectFriend" :key="i">
                  <div class="d-flex align-items-center">
                    <div class="imgBox">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="name textOverflow">{{ item.nick_name }}</div>
                  </div>
                  <div class="d-flex align-items-center cursor-pointer" @click="removeSelect(item, i)">
                    <img src="@/assets/images/public/close-s.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="d-flex justify-content-end">
              <b-button style="background: #fff5f4; border: 0; color: #ff9b8a" class="mr-2"
                @click="$bvModal.hide('addModifyPopup')">
                Cancel</b-button>
              <b-button variant="primary" class="mr-3" @click="confirmInvite">
                <!--Confirm  -->
                {{ $t("Club.d5e@confirm") }}
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- 修改 -->
      <b-modal style="max-width: 800px" id="createAGroup" centered :title="$t('Club.217@modify')" hide-footer>
        <section class="createAGroupModal">
          <!-- <div class="img_box">
            <div
              style="
                width: 150px;
                height: 150px;
                border-radius: 50%;
                overflow: hidden;
                top: 10px;
                border: 1px solid #f1f1f1;
              "
            >
              <img
                style="
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                "
                ref="imgAva"
                :src="form.headimg"
              />
            </div>
            <div class="edit-pen">
              <el-upload
                class="avatar-uploader"
                action="no"
                :show-file-list="false"
                :http-request="uploadAvatar"
              >
                <i class="las la-pen"></i>
              </el-upload>
            </div>
          </div> -->

          <b-form class="formContent">
            <b-form-group label="Cover">
              <el-upload class="avatar-uploader" style="
                  width: 90px;
                  height: 90px;
                  display: inline-block;
                  border: 1px dashed #cecece;
                  border-radius: 5px;
                  text-align: center;
                " action="no" :show-file-list="false" :http-request="uploadAvatar">
                <img style="width: 90px; height: 90px" src="../../../assets/images/albums/camera.png" alt="" />
              </el-upload>
              <div v-if="form.headimg" class="backImag ml-2" style="display: inline-block">
                <img style="width: 90px; height: 90px; border-radius: 5px" :src="form.headimg" alt="" />
                <img class="closeImg" @click="delImage(1)" src="@/assets/images/public/close.png" alt="" />
              </div>
            </b-form-group>
            <b-form-group label="Banner">
              <el-upload class="avatar-uploader" action="no" style="
                  width: 180px;
                  display: inline-block;
                  border: 1px dashed #cecece;
                  border-radius: 5px;
                  text-align: center;
                " :show-file-list="false" :http-request="uploadBackground">
                <img style="width: 90px; height: 90px" src="../../../assets/images/albums/camera.png" alt="" />
              </el-upload>
              <div class="backImag ml-2" v-if="form.background" style="display: inline-block">
                <img style="width: 180px; height: 90px; border-radius: 5px" :src="form.background" alt="" />
                <img class="closeImg" @click="delImage(2)" src="@/assets/images/public/close.png" alt="" />
              </div>
            </b-form-group>
            <b-form-group label="Interest Club Name">
              <!-- <b-form-group :label="$t('Club.d37@group_name')"> -->
              <b-form-input v-model="form.group_name" type="text"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('Club.889@privacy_settings')">
              <select class="form-control birthdaySelect" v-model="form.visibility">
                <option :value="1">{{ $t("Club.aa1@private") }}</option>
                <option :value="2">{{ $t("Club.c8e@public") }}</option>
              </select>
            </b-form-group>
            <!-- <b-form-group>
              <CountrySelection
                @returnPrams="getAddressParams"
                :grandsonClassName="['col-md-6']"
                CountryLabel="Country"
                RegionLabel="Region"
                :groupDetail="groupDetail"
              />
            </b-form-group> -->
            <b-form-group label="Country">
              <select class="form-control" v-model="form.country_id" style="width: 300px">
                <!-- <option value="">All</option> -->
                <option v-for="item of countryList" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </b-form-group>
            <b-form-group label="Introduction">
              <quill-editor ref="myQuillEditor" class="QuillEditor" v-model="form.introduce" :options="editorOption" />
            </b-form-group>
            <b-form-group label="Interest Club Rules">
              <quill-editor ref="myQuillEditor" class="QuillEditor" v-model="form.rules" :options="editorOption" />
            </b-form-group>
          </b-form>
          <div class="d-flex justify-content-end">
            <b-button class="btnOrange" @click="$bvModal.hide('createAGroup')">
              {{ $t("Club.57b@cancel") }}
            </b-button>
            <b-button class="ml-3" variant="primary" @click="confirmModal">
              {{ $t("Club.bbc@save") }}
            </b-button>
          </div>
        </section>
      </b-modal>

      <b-modal id="picModal" :title="$t('Club.771@select_image_focus')" hide-footer>
        <div style="width: 300; height: 300px">
          <vueCropper ref="cropper" :img="option.img" :outputSize="1" :autoCrop="true" :canScale="true"
            :canMoveBox="false" :fixedNumber="[1, 1]" :fixedBox="true" canMove fixed></vueCropper>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <b-button class="mr-2" @click="$bvModal.hide('picModal')">
            {{ $t("Club.57b@cancel") }}</b-button>
          <b-button variant="primary" @click="catAvatar">{{
            $t("Club.d5e@confirm")
            }}</b-button>
        </div>
      </b-modal>
    </b-row>
    <div v-if="isShowScrollNumber" @click="postListToTop" class="toTop">
      <i class="ri-upload-line"></i>
    </div>
  </section>
</template>
<script>
  import { mapGetters, mapMutations, mapState } from "vuex";
  import Timeline from "./components/Timeline";
  import Private from "./components/Private";
  import About from "./components/About";
  import Members from "./components/Members";
  import Media from "./components/Media";
  import CountrySelection from "@/components/ComComponents/CountrySelection";

  import { quillEditor } from "vue-quill-editor";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  export default {
    name: "Profile",
    data() {
      return {
        groupBaseInfo: {},
        groupPostList: [],
        friendsList: [],
        isShowScrollNumber: 0,

        option: {
          img: "",
        },
        form: {
          headimg: "",
          background: "",
          group_name: "",
          visibility: "",
          rules: "",
          country_id: "",
          introduce: "",
          // region_id: "",
        },
        searchUser: "",
        filterSelectFriend: [],
        filterId: [],
        countryList: [],
        groupDetail: {},
        editorOption: {
          placeholder: "Insert Text Here",
          modules: {
            toolbar: [
              ["bold"], // 加粗
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], // 列表
              [
                {
                  size: ["small", false, "large", "huge"],
                },
              ], // 字体大小
              [
                {
                  color: [],
                },
                {
                  background: [],
                },
              ], // 字体颜色，字体背景颜色
              [
                {
                  font: [],
                },
              ], // 字体
              [
                {
                  align: [],
                },
              ], // 对齐方式
            ],
          },
        },
      };
    },
    components: {
      About,
      Timeline,
      Members,
      Media,
      Private,
      quillEditor,
      CountrySelection,
    },

    computed: {
      // 获取当前用户相关信息
      ...mapGetters({
        dataChat: "ChatRoom/getDataChat",
      }),
      ...mapState({
        userType: (state) => state.Group.userType,
      }),
      // 选中的好友列表
      // filterSelectFriend() {
      //   return this.friendsList.filter((item) => item.select == true);
      // },
    },

    filters: {
      userTypeFilter(val) {
        return val == 0 ? "Join" : "Invite";
      },
    },

    created() {
      this.getGroupInfo();
      this.getUserFriend();
      this.getCountry();
      // this.getGroupPost()
      // this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      //   this.form.headimg = "";
      //   this.form.group_name = "";
      //   this.form.visibility = "";
      //   this.form.country_id = '';
      //   this.form.region_id = '';
      //   this.form.rules = "";
      // })
    },

    mounted() {
      let postListContainer = this.$refs.profile;
      postListContainer.addEventListener('scroll', (e) => {
        this.isShowScrollNumber = e.target.scrollTop
      })
    },

    methods: {
      postListToTop() {
        let postListContainer = this.$refs.profile;
        let top = postListContainer.scrollTop;
        // 实现滚动效果 
        const timeTop = setInterval(() => {
          postListContainer.scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      },

      InfinitePostData() {
        this.$refs.timeLine.$emit('funInfinitePost')
      },

      clickMedia() {
        this.$refs.media.getPhotoList();
        this.$refs.media.getVideoList();
      },
      delImage(type) {
        if (type == 1) {
          this.form.headimg = "";
        } else {
          this.form.background = "";
        }
      },
      getCountry() {
        this.$http.getAddress({ level: 2 }).then((res) => {
          if (res.status == 200) {
            this.countryList = res.data;
          }
        });
      },
      getAddressParams(log) {
        this.form.country_id = log.countryid;
        this.form.region_id = log.regionid;
      },

      // 上传头像
      uploadAvatar(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.form.headimg = res.data[0];
          }
        });
        // var reader = new FileReader();
        // reader.readAsDataURL(file.file);
        // let that = this;
        // reader.onload = function (e) {
        //   that.option.img = this.result;
        //   that.$bvModal.show("picModal");
        // };
      },
      uploadBackground(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.form.background = res.data[0];
          }
        });
      },

      catAvatar() {
        this.$refs.cropper.getCropBlob(async (blobFile) => {
          let formData = new FormData();
          let fileOfBlob = new File([blobFile], getName() + ".jpg");
          formData.append("file", fileOfBlob);
          let { data } = await this.$http.uploadImage(formData);
          this.form.headimg = data[0];
          this.$bvModal.hide("picModal");

          // 生成唯一标识
          function getName() {
            return +(
              parseInt(new Date().getTime() / 1000) +
              "" +
              Math.round(Math.random() * (9999 - 1000) + 1000)
            );
          }
        });
      },

      // 修改bg
      imgUpload1(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.bg_img = res.data[0];
            this.$http
              .getBgImg({
                group_id: this.$route.query.id,
                background: this.bg_img,
              })
              .then((res) => {
                this.getGroupInfo();
              });
          }
        });
      },

      queryGroupInfo() {
        this.$bvModal.show("createAGroup");
        this.$http
          .getGroupInfo({
            group_id: this.$route.query.id,
          })
          .then((res) => {
            this.groupDetail = res.data;
            this.form.headimg = res.data.headimg;
            this.form.background = res.data.background;
            this.form.group_name = res.data.group_name;
            this.form.visibility = res.data.visibility;
            this.form.country_id = res.data.country_id;
            // this.form.region_id = res.data.region_id;
            this.form.introduce = res.data.introduce;
            this.form.rules = res.data.rules;
          });
      },

      // addGroup
      async confirmModal() {
        let { status } = await this.$http.updateGroupInfo({
          group_id: this.$route.query.id,
          ...this.form,
          country_id: this.form.country_id,
          region_id: this.form.region_id,
        });
        if (status == 200) {
          this.$bvModal.hide("createAGroup");
          this.getGroupInfo();
        }
      },

      joinGroup(info) {
        this.filterSelectFriend = [];
        this.filterId = [];
        this.searchUser = "";
        this.getUserFriend();
        if (info.user_type != 0) {
          this.$bvModal.show("addModifyPopup");
        } else {
          this.$http
            .joinGroup({
              group_id: this.$route.query.id,
            })
            .then((res) => {
              if (res.status == 200) {
                this.getGroupInfo();
              }
            });
        }
      },
      getGroupInfo() {
        this.$http
          .getGroupInfo({
            group_id: this.$route.query.id,
          })
          .then((res) => {
            this.groupBaseInfo = res.data;
            this.form.rules = res.data.rules;
            this.$store.commit("updateUserType", res.data.user_type);
          });
      },

      getGroupPost() {
        this.$http
          .getGroupPost({
            group_id: this.$route.query.id,
          })
          .then((res) => {
            // console.log('post', res);
            this.groupPostList = res.data;
          });
      },

      // 获取好友列表
      // 获取用户好友列表并判断是否在线
      async getUserFriend() {
        const res = await this.$http.getUserFriend({
          keyword: this.searchUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (res.status == 200) {
          // this.friendsList = res.data;
          // this.friendsList.forEach((v, i) => {
          //   this.$set(v, "select", false);
          // });

          res.data.forEach((v) => {
            if (this.filterId.includes(v.user_id)) {
              this.$set(v, "select", true);
            } else {
              this.$set(v, "select", false);
            }
          });
          this.friendsList = res.data;
          // console.log(this.friendsList);
        }
      },
      addUserList(item) {
        if (!item.select) {
          this.filterSelectFriend.push(item);
          this.filterId.push(item.user_id);
        } else {
          this.filterSelectFriend.forEach((v, i) => {
            if (v.user_id == item.user_id) {
              this.filterSelectFriend.splice(i, 1);
              this.filterId.splice(i, 1);
            }
          });
        }
      },

      // 移除选中的群友
      removeSelect(item, i) {
        this.$set(item, "select", false);
        this.filterSelectFriend.splice(i, 1);
        this.filterId.splice(i, 1);
        this.friendsList.forEach((v) => {
          if (v.user_id == item.user_id) {
            this.$set(v, "select", false);
          }
        });
      },
      // 分享内容处理
      sharefilterParams(item) {
        let params = {};
        params.share_type = 11;
        params.share_msg = item;
        return JSON.stringify(params);
      },

      confirmInvite() {
        let userId = [];
        this.filterSelectFriend.forEach((item) => {
          userId.push(item.user_id);
        });
        this.$http
          .invateGroup({
            group_id: this.$route.query.id,
            user_id: userId,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$bvModal.hide("addModifyPopup");
              this.filterSelectFriend = [];
            }
          });

        // console.log("this.groupBaseInfo", this.groupBaseInfo);
        // 分享给好友
        let content = this.sharefilterParams(this.groupBaseInfo);
        for (let i = 0; i < userId.length; i++) {
          const data = {
            push_user_id: userId[i],
            msg_type: "10",
            content,
            client_id: this.dataChat.client_id,
          };
          this.$http.sendChat(data).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "Share success！",
                type: "success",
              });
              this.$bvModal.hide("friendPopup");
            }
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .QuillEditor {
    width: 100%;

    ::v-deep .ql-container {
      height: 182px;
    }
  }

  #createAGroup {
    ::v-deep .modal-dialog {
      max-width: 800px;
    }
  }

  .backImag {
    position: relative;

    .closeImg {
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  //  modal-md modal-dialog-centered
  .GroupDetail {
    .iconSize {
      font-size: 17px;
      vertical-align: baseline;
    }

    .headerContent {
      padding: 0 15px 20px 30px;

      .avatarBox {
        margin-top: -55px;
        width: 140px;
        height: 140px;
        border: 2px solid #ffffff;

        img {
          width: 100%;
        }
      }
    }

    .tabsCard {
      min-height: 65px;

      .navTabs {
        ::v-deep .nav-item a {
          padding-bottom: 16px;
        }
      }
    }
  }

  // icon(不需要响应式)
  .cover-container {
    position: relative;
    height: 230px;

    >img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ::v-deep #addModifyPopup {
    .modal-dialog {
      .textOverflow {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .modal-content {
        min-height: 520px !important;

        .modal-body {
          padding: 0;

          .contentLeft {
            padding: 1rem;
            padding-left: 22px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }

          .contentRight {
            padding: 1rem;
            padding-right: 30px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .title {
              font-size: 14px;
              color: #666666;
              margin-bottom: 30px;
              text-align: center;
            }
          }

          .imgBox {
            min-width: 30px;
            min-height: 30px;
          }
        }
      }
    }
  }

  // pc
  @media (min-width: 992px) {

    // 邀请朋友
    ::v-deep #addModifyPopup {
      .modal-dialog {
        max-width: 620px !important;
      }
    }

    .space-box {
      min-height: 100px;
    }

    // icon-circle
    .profile-icon-box {
      position: absolute;
      left: auto;
      right: 0;
      top: auto;
      bottom: 20px;

      li {
        list-style: none;

        a {
          display: block;
          list-style: none;
          height: 40px;
          width: 40px;
          background: var(--iq-white);
          border-radius: 50%;
          line-height: 40px;
          text-align: center;
          color: var(--iq-primary);
          font-size: 18px;
          margin: 0 1.071429rem 0 0;
          cursor: pointer;
        }
      }
    }

    // avatar-block
    .user-info {
      position: relative;
      height: 70px;

      .avatar-box {
        width: 140px;
        height: 140px;
        border: 2px solid #ffffff;
        overflow: hidden;
        position: absolute;
        top: -60px;
        left: 30px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .flow-data {
        position: absolute;
        top: 0;
        left: 190px;

        .title {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .btn-box {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px !important;
      margin-right: 15px !important;

      >li:nth-child(1) {
        margin-right: 10px;
      }

      .btn {
        width: 120px;
      }
    }

    .slogan {
      padding-left: 30px;
    }
  }

  // 超小屏
  @media (max-width: 992px) {
    .space-box {
      height: 120px;
    }

    // icon-circle
    .profile-icon-box {
      position: absolute;
      left: auto;
      right: 0;
      top: auto;
      bottom: 20px;

      li {
        list-style: none;

        a {
          display: block;
          list-style: none;
          height: 30px;
          width: 30px;
          background: var(--iq-white);
          border-radius: 50%;
          line-height: 30px;
          text-align: center;
          color: var(--iq-primary);
          font-size: 18px;
          margin: 0 1.071429rem 0 0;
        }
      }
    }

    // avatar-block
    .user-info {
      position: relative;
      height: 45px;

      .avatar-box {
        width: 75px;
        height: 75px;
        border: 2px solid #ffffff;
        overflow: hidden;
        position: absolute;
        top: -30px;
        left: 10px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .flow-data {
        position: absolute;
        top: 0;
        left: 93px;

        .title {
          font-size: 14px;
          font-weight: 500;
        }

        .soicalInfo {
          li {
            margin-right: 12px !important;
            padding: 0 !important;
            font-size: 12px;

            p {
              margin-right: 7px !important;
            }

            h6 {
              font-size: 13px;
            }
          }
        }
      }
    }

    .btn-box {
      display: flex;
      margin-left: 10px !important;

      >li:nth-child(1) {
        margin-right: 10px;
      }

      .btn {
        width: 90px;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-size: 14px;
      }
    }

    .slogan {
      padding-left: 10px;
      font-size: 10px;
    }
  }

  #createAGroup {
    .createAGroupModal {
      .img_box {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        position: relative;

        >.edit-pen {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          z-index: 9999;
          text-align: center;
          line-height: 30px;
          background-color: #50b5ff;
          color: #fff;
          position: absolute;
          bottom: 17px;
          left: 118px;

          .el-upload {
            position: relative;

            i {
              position: absolute;
              top: 3px;
              left: 5px;
            }
          }
        }
      }

      .formContent {
        #Introduction {
          height: 85px;
          line-height: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    ::v-deep #createAGroup .modal-dialog {
      max-width: 800px !important;
    }
  }
</style>