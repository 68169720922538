<template>
  <section class="Private">
    <div>
      <img class="img-fluid" src="@/assets/images/group/private.png" alt="" />
    </div>
    <div class="title">{{$t('Club.7b9@this_is_a')}} {{visibility == 1 ? "private" : "public"}} {{$t('Club.a86@group')}}  </div>
    <div class="desc">
      
      {{$t('Club.3f8@after_joining_the_gr')}}
    </div>
  </section>
</template>
<script>
export default {
  props:{
    visibility:{
      type:Number
    }
  },
  name: "",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
};
</script>
<style src='' lang='scss' scoped>
.Private {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin: 40px 0 19px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .desc {
    width: 289px;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 24px;
  }
}
</style>