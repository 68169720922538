<template>
  <section class="Media">
    <!-- tabs -->
    <tab-nav :tabs="true" id="myTab-1">
      <!-- Photos -->
      <tab-nav-items
        :active="true"
        id="photos-tab-two"
        ariaControls="photos"
        role="tab"
        href="#photo"
        :ariaSelected="true"
        :title="$t('Club.ff1@photos')"
      />
      <!-- Video -->
      <tab-nav-items
        :active="false"
        id="video-tab-two"
        ariaControls="video"
        role="tab"
        href="#video"
        :ariaSelected="false"
        title="Video"
      />
    </tab-nav>
    <!-- tabs => 内容 -->
    <tab-content>
      <!-- Photos -->
      <tab-content-item
        :active="true"
        id="photo"
        aria-labelled-by="photos-tab-two"
      >
        <div class="topTitle">
          <div style="color: #333; font-size: 16px; font-weight: 500">
            All photos
          </div>
          <el-upload
            v-if="userType==2||userType==3"
            class="avatar-uploader"
            action="no"
            :http-request="photoUpload"
            :show-file-list="false"
            >
              <b-button variant="none" class="iq-bg-primary" >+ Add Photos</b-button>
          </el-upload>
        </div>
        <b-row class="row">
          <b-col
            v-for="(item, index) in photoList"
            :key="index"
            sm="6"
            md="6"
            lg="3"
            class="mb-3"
          >
            <div class="media_photo_box">
              <div class="photo_box">
                <img @click="handleClickPopup(item.path)" :src="item.path" alt="" />
              </div>
              <ul v-if="item.post_id" class="d-flex align-items-center m-0 list-inline icon_box">
                <li class="pr-3 text-white" @click="likePostGroup(item)">
                  {{ item.like_total }}
                  <i
                    class="ri-thumb-up-line"
                    :style="{ color: item.is_like ? '#50b5ff' : '' }"
                  ></i>
                </li>
                <li class="pr-3 text-white" @click="openSelfModal(item)">
                  {{ item.comment_total }} <i class="ri-chat-3-line"></i>
                </li>
                <li class="pr-3 text-white" @click="shareStaus(item)">
                  {{ item.share_total }} <i class="ri-share-forward-line"></i>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- Video -->
      <tab-content-item
        :active="false"
        id="video"
        aria-labelled-by="video-tab-two"
      >
        <div class="topTitle">
          <div  style="color: #333; font-size: 16px; font-weight: 500">
            All Video
          </div>
          <el-upload
            v-if="userType==2||userType==3"
            class="avatar-uploader"
            action="no"
            :http-request="addClubVideo"
            :show-file-list="false"
            >
              <b-button variant="none" class="iq-bg-primary" >+ Add Vedio</b-button>
          </el-upload>
        </div>
        <b-row class="row">
          <b-col
            md="6"
            lg="3"
            class="mb-3"
            v-for="(item, index) in videoList"
            :key="index"
          >
            <div class="media_photo_box">
              <div class="photo_box">
                <video
                  class="img-fluid rounded w-100 h-100"
                  :src="item.path"
                  :id="item.video_id"
                  alt=""
                />
                <i
                  v-if="item.isPlay == 1"
                  class="ri-pause-circle-line playIcon"
                  @click="pauseVideo(item)"
                ></i>
                <i
                  v-else
                  class="ri-play-circle-line playIcon"
                  @click="playVideo(item)"
                ></i>
              </div>
              <ul v-if="item.post_id" class="d-flex align-items-center m-0 list-inline icon_box">
                <li class="pr-3 text-white">
                  {{ item.like_total }}
                  <i
                    class="ri-thumb-up-line"
                    :style="{ color: item.is_like ? '#50b5ff' : '' }"
                  ></i>
                </li>
                <li class="pr-3 text-white">
                  {{ item.comment_total }} <i class="ri-chat-3-line"></i>
                </li>
                <li class="pr-3 text-white">
                  {{ item.share_total }} <i class="ri-share-forward-line"></i>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </tab-content-item>
    </tab-content>

    <!-- SecondList -->
    <b-modal
      :id="replayListModalId"
      centered
      :title="replayModalTitle"
      hide-footer
      scrollable
    >
      <!-- 父级评论 -->
      <ul class="comment_and_replay p-0 m-0">
        <li>
          <div class="d-flex justify-content-between align-items-top">
            <div class="user_avatar mr-2">
              <img :src="parentcomment.avatar" alt="" />
            </div>
            <div class="comment_content">
              <div class="comment_main d-flex">
                <span>{{ parentcomment.nick_name }}</span
                >：
                <div class="m-0 d-flex">
                  {{
                    parentcomment.content &&
                    JSON.parse(parentcomment.content).content
                  }}
                  <ul
                    v-if="
                      parentcomment.content &&
                      JSON.parse(parentcomment.content).img.length
                    "
                    class="simple_img d-flex p-0 m-0"
                  >
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(parentcomment.content)"
                        >View</span
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="time_icon">
                <span>{{ parentcomment.created_at }}</span>
                <ul class="m-0 p-0">
                  <li @click="parentcomment.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li
                    :class="{
                      'text-secondary': !parentcomment.is_like,
                      'text-primary': parentcomment.is_like,
                    }"
                    @click="likePostComment(parentcomment)"
                  >
                    <i class="ri-thumb-up-line"></i
                    >{{ parentcomment.like_total }}
                  </li>
                </ul>
              </div>
              <div
                v-if="parentcomment.isShowReplayForm"
                class="replay_white_box"
              >
                <input
                  v-model="parentcomment.replayForm"
                  :placeholder="$t('Club.46e@reply')"
                  type="text"
                />
                <div class="mt-2">
                  <b-button
                    @click="
                      replayModalComment(
                        parentcomment.user_post_id,
                        parentcomment,
                        parentcomment.replayForm,
                        post
                      )
                    "
                    variant="primary"
                    size="sm"
                  >
                    {{ $t("Club.46e@reply") }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- 二级评论详细内容 -->
          <ul class="p-0">
            <li v-for="second in secondCommentList" :key="second.id">
              <div class="second_comment d-flex">
                <span class="mr-1">{{ second.nick_name }}</span>
                <p class="m-0" v-if="second.parent != 1">
                  {{ $t("Club.46e@reply")
                  }}<span class="text-primary ml-1">{{
                    second.parent.nick_name
                  }}</span>
                </p>
                :
                <p
                  class="m-0"
                  v-if="
                    second.content &&
                    !JSON.parse(second.content).content &&
                    !JSON.parse(second.content).img
                  "
                >
                  {{ second.content }}
                </p>

                <div class="m-0 d-flex">
                  {{ second.content && JSON.parse(second.content).content }}
                  <ul
                    v-if="
                      second.content &&
                      JSON.parse(second.content).img &&
                      JSON.parse(second.content).img.length
                    "
                    class="simple_img d-flex p-0 m-0"
                  >
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(second.content)">View</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="
                  second_time
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <span>{{ second.created_at }}</span>
                <ul class="m-0 p-0 d-flex second_icon">
                  <li
                    v-if="second.is_user_send"
                    @click="deleteModalComment(second, post)"
                  >
                    <i class="ri-delete-bin-line"></i>
                  </li>
                  <li @click="second.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li
                    :class="{
                      'text-secondary': !second.is_like,
                      'text-primary': second.is_like,
                    }"
                    @click="likePostComment(second)"
                  >
                    <i class="ri-thumb-up-line"></i>
                    {{ second.like_total }}
                  </li>
                </ul>
              </div>
              <div
                v-if="second.isShowReplayForm"
                class="left_padding_white_box"
              >
                <input
                  v-model="second.replayForm"
                  :placeholder="$t('Club.46e@reply')"
                  type="text"
                />
                <div class="mt-2">
                  <b-button
                    @click="
                      replayModalComment(
                        second.user_post_id,
                        second,
                        second.replayForm,
                        post,
                        true
                      )
                    "
                    variant="primary"
                    size="sm"
                    >{{ $t("Club.46e@reply") }}</b-button
                  >
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </b-modal>

    <!-- second Comment input -->
    <b-modal
      :id="repalyFormModalId"
      centered
      :title="textareaModalTitle"
      hide-footer
    >
      <el-upload
        class="avatar-uploader"
        action="no"
        :http-request="imgUploadEr"
        :show-file-list="false"
      >
        <i class="ri-camera-line mr-2" style="font-size: 30px"></i>
      </el-upload>

      <!-- 评论的图片列表 -->
      <ul class="p-0 m-0 media_box">
        <li class="li_pic" v-for="(item, index) in commentMediaEr" :key="index">
          <img v-if="item.type == 'photo'" :src="item.url" alt="" />
          <video v-else :src="item.url" controls autoplay></video>
          <img
            class="close_img"
            src="../../../../assets/images/add_post_close.png"
            @click="commentMediaEr.splice(index, 1)"
            alt=""
            style="cursor: pointer"
          />
        </li>
      </ul>

      <b-form-textarea
        id="textarea"
        :placeholder="$t('Club.b4d@enter_something...')"
        v-model="replay_textarea"
        rows="3"
        max-rows="6"
      >
      </b-form-textarea>

      <b-button
        @click="replayPostComment(post)"
        class="mt-2"
        block
        variant="primary"
        >{{ $t("Club.46e@reply") }}
      </b-button>
    </b-modal>

    <!-- PostDetail -->
    <groupSelfModal
      v-if="isShowSelfModal"
      style="width: 100%; height: 100%; position: fixed; top: 0; left: 0"
      @close="closeSelfModal"
      :postDetail="post"
      :mediaIndex="mediaIndex"
    />
    <div class="popup" v-if="imgIsShow">
      <div class="imgflex">
        <img class="img" :src="src" alt="" />
        <img
          src="../../../../assets/images/public/close.png"
          @click="imgIsShow = false"
          alt=""
          class="iconImg"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import bus from "@/Utils/Bus";
import groupSelfModal from "../../components/groupSelfModal";
import { socialvue } from "../../../../config/pluginInit";
export default {
  components: {
    groupSelfModal,
  },
  name: "ProfileImage",
  mounted() {
    socialvue.index();
  },
  computed: {
    replayModalTitle() {
      return `${this.secondCommentList.length} Reply`;
    },

    textareaModalTitle() {
      return `Reply ${this.firstCommentObj.nick_name}`;
    },

    repalyFormModalId() {
      return `commentFormGroup${this.firstCommentObj.id}`;
    },

    replayListModalId() {
      return `secondCommentListModalGroup${this.commentListParent.id}`;
    },
    ...mapState({
      userType: (state) => state.Group.userType,
    }),
  },
  data() {
    return {
      photoList: [],
      videoList: [],
      otherInfo: [
        {
          class: "ri-thumb-up-line",
          value: 10,
        },
        {
          class: "ri-chat-3-line",
          value: 2,
        },
        {
          class: "ri-share-forward-line",
          value: 1,
        },
      ],

      isShowSelfModal: false,
      imgIsShow: false,
      post: {},
      mediaIndex: 0,
      // 回复弹框
      firstCommentPage: 0,
      replay_textarea: "",
      // 传递的comment信息
      firstCommentObj: {},
      // firstCommentList: [],
      // 二级评论弹框
      parentcomment: {},
      secondCommentList: [],
      commentListParent: {},
      // 评论图片列表
      commentMedia: [],
      commentMediaEr: [],

      src:"",
    };
  },
  mounted() {
    this.getPhotoList();
    this.getVideoList();
  },
  methods: {
    handleClickPopup(licence) {
      this.imgIsShow = true;
      this.src = licence;
    },
    async addClubVideo(params) {
      let formData = new FormData();
      // formData.append("model_id", this.$route.query.model_id);
      // formData.append("model_type", this.modelType);
      formData.append("media", params.file);
      let res = await this.$http.uploadFile(formData);
      if (res.status == 200) {
        await this.$http.addClubVideo({ club_id: this.$route.query.id, url: res.data[0] }).then((res) => {
          if (res.status == 200) {
            this.currentVid = 1;
            this.getVideoList();
          }
        });
      }
    },
    photoUpload(params) {
      // console.log(this.$route.query.id);
      this.loading = true;
      var formData = new FormData();
      formData.append("file", params.file);
      try {
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.$http
              .addClubPhoto({
                club_id: this.$route.query.id,
                url: res.data[0],
              })
              .then((res) => {
                if (res.status == 200) {
                  this.getPhotoList();
                  this.loading = false;
                }
              });
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
    playVideo(item) {
      var video = document.getElementById(item.video_id);
      video.play();
      item.isPlay = 1;
    },
    pauseVideo(item) {
      var video = document.getElementById(item.video_id);
      video.pause();
      item.isPlay = 0;
    },
    getPhotoList() {
      this.$http
        .getAllPhoto({
          model_type: 4,
          model_id: this.$route.query.id,
        })
        .then((res) => {
          this.photoList = res.data.data;
        });
    },
    getVideoList() {
      this.videoList=[];
      this.$http
        .getAllVideos({
          model_type: 4,
          model_id: this.$route.query.id,
        })
        .then((res) => {
          res.data.data.forEach((item) => {
            this.videoList.push({ ...item, isPlay: 0 });
          });
          // this.videoList = res.data.data;
        });
    },

    // 点赞帖子
    likePost(post) {
      this.$http
        .likePost({
          user_post_id: post.id,
        })
        .then((res) => {
          if (res.status == 200 && !post.user_post_like) {
            // 判断是点赞还是取消点赞
            post.like_total++;
          } else if (post.user_post_like) {
            post.like_total--;
          }
          this.$set(post, "user_post_like", !post.user_post_like);
        });
    },

    likePostGroup(post) {
      this.$http
        .likePost({
          user_post_id: post.post_id,
        })
        .then((res) => {
          if (res.status == 200 && !post.is_like) {
            // 判断是点赞还是取消点赞
            post.like_total++;
          } else if (post.is_like) {
            post.like_total--;
          }
          post.is_like = !post.is_like;
        });
    },

    async openSelfModal(item) {
      // 获取帖子的信息
      let res = await this.$http.postDetail({
        user_post_id: item.user_post_id,
      });
      this.$set(res.data, "firstCommentList", []);
      this.post = res.data;
      this._getFirstCommentList(this.post, 1);
      this.isShowSelfModal = true;
    },

    // 获取顶级评论列表
    _getFirstCommentList(post, page) {
      this.$http
        .getPostCommentInfo({
          user_post_id: post.id,
          page,
          size: 50,
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$set(post, "firstCommentList", res.data.data);
          });
        });
    },
    // 关掉自制modal
    closeSelfModal() {
      this.isShowSelfModal = false;
      this.getPhotoList();
    },

    // 上传
    imgUpload(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200 && this.commentMedia.length < 9) {
          this.commentMedia.push({
            type: params.file.type.split("/")[0] == "image" ? "photo" : "video",
            url: res.data[0],
          });
        }
      });
    },

    // 二级上传
    imgUploadEr(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200 && this.commentMediaEr.length < 9) {
          this.commentMediaEr.push({
            type: params.file.type.split("/")[0] == "image" ? "photo" : "video",
            url: res.data[0],
          });
        }
      });
    },
    // 分享
    shareStaus(post) {
      bus.$emit("share", post);
    },
    commentPost(post) {
      // 评论内容格式化
      let imgTextContent = {};
      imgTextContent.content = this.commentPostContent;
      imgTextContent.img = this.commentMedia;

      this.$http
        .commentPost({
          type: this.computedPageType,
          user_post_id: post.id,
          parent: 0,
          content: JSON.stringify(imgTextContent),
          commet_parent: "", // 不能省
        })
        .then((res) => {
          if (res.status == 200) {
            post.firstCommentList.unshift(res.data);
            this.commentPostContent = "";
            // this.commentPostContent = ''
            this.commentMedia = [];

            // console.log(post);
            let plus1 = post.comment_total + 1;
            this.$set(post, "comment_total", plus1);
          }
          this.$set(comment, "is_like", !comment.is_like);
        });
    },

    // 点赞评论
    likePostComment(comment) {
      this.$http
        .likePostComment({
          user_post_comment_id: comment.id,
        })
        .then((res) => {
          if (res.status == 200) {
            // 判断是点赞还是取消点赞
            comment.like_total++;
          } else {
            comment.like_total--;
          }
          this.$set(comment, "is_like", !comment.is_like);
        });
    },

    // 删除评论
    delSelfPostComment(post, comment) {
      this.$confirm(
        this.$t("Club.318@this_operation_will_"),
        this.$t("Club.00c@tips"),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t("Club.d5e@confirm"),
          cancelButtonText: this.$t("Club.57b@cancel"),
          type: "warning",
        }
      ).then(() => {
        // console.log("comment", comment);
        // console.log("post", post);
        this.$http
          .delPostComment({
            user_post_comment_id: comment.id,
          })
          .then((res) => {
            // 删除顶级列表的某一项
            this.$set(
              post,
              "firstCommentList",
              post.firstCommentList.filter((some) => some.id !== comment.id)
            );
            let subd_n = post.comment_total - comment.comment_total - 1;
            this.$set(post, "comment_total", subd_n);
          });
      });
    },

    /**
     * ----------二级评论列表弹框--------
     * */

    // 打开二级评论列表
    secondCommentOpen(commentInfo) {
      // 设置二级弹框列表的唯一性
      this.commentListParent = commentInfo;
      // 打开弹框
      this.$nextTick(() => {
        this.$bvModal.show(`secondCommentListModalGroup${commentInfo.id}`);
      });
    },

    // 删除评论
    delSelfPostComment(post, comment) {
      this.$confirm(
        this.$t("Club.318@this_operation_will_"),
        this.$t("Club.00c@tips"),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t("Club.d5e@confirm"),
          cancelButtonText: this.$t("Club.57b@cancel"),
          type: "warning",
        }
      ).then(() => {
        // console.log("comment", comment);
        // console.log("post", post);
        this.$http
          .delPostComment({
            user_post_comment_id: comment.id,
          })
          .then((res) => {
            // 删除顶级列表的某一项
            this.$set(
              post,
              "firstCommentList",
              post.firstCommentList.filter((some) => some.id !== comment.id)
            );
            let subd_n = post.comment_total - comment.comment_total - 1;
            this.$set(post, "comment_total", subd_n);
          });
      });
    },

    /**
     * ----------二级评论列表弹框--------
     * */

    // 打开二级评论列表
    secondCommentOpen(commentInfo) {
      // 设置二级弹框列表的唯一性
      this.commentListParent = commentInfo;
      // 打开弹框
      this.$nextTick(() => {
        this.$bvModal.show(`secondCommentListModalGroup${commentInfo.id}`);
      });

      // 获取二级评论列表
      this.$http
        .getChildComment({
          user_post_comment_id: commentInfo.id,
        })
        .then((res) => {
          // 列表
          this.secondCommentList = res.data.children_commet.data;
          this.secondCommentList.forEach((item) => {
            this.$set(item, "isShowReplayForm", false);
            this.$set(item, "replayForm", "");
          });

          // 父级
          this.parentcomment = commentInfo;
          this.$set(this.parentcomment, "isShowReplayForm", false);
          this.$set(this.parentcomment, "replayForm", "");
          this.$set(this.parentcomment, "childresComment", []);
        });
    },

    // 在弹框上回复评论---“回复”按钮
    replayModalComment(postId, comment, commentContent, post, flag) {
      // 评论内容格式化
      let imgTextContent = {};
      imgTextContent.content = commentContent;
      // imgTextContent.img = [];
      this.$http
        .commentPost({
          type: this.computedPageType,
          user_post_id: postId,
          parent: comment.id,
          commet_parent: comment.commet_parent
            ? comment.commet_parent
            : comment.id,
          content: JSON.stringify(imgTextContent),
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 200) {
            // console.log(res);
            // 给自制二级评论添加两个变量控制回复框的显示和值
            this.$set(res.data, "isShowReplayForm", false);
            this.$set(res.data, "replayForm", "");

            this.secondCommentList.unshift(res.data);

            // 隐藏回复框和清空
            comment.replayForm = "";
            comment.isShowReplayForm = false;
            post.comment_total++;
            if (flag) {
              this.parentcomment.comment_total++;
            } else {
              comment.comment_total++;
            }
          }
        });
    },

    // 删除modal评论
    deleteModalComment(second, post) {
      // console.log(second);
      this.$confirm(
        this.$t("Club.318@this_operation_will_"),
        this.$t("Club.00c@tips"),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t("Club.d5e@confirm"),
          cancelButtonText: this.$t("Club.57b@cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$http
          .delPostComment({
            user_post_comment_id: second.id,
          })
          .then((res) => {
            this.secondCommentList = this.secondCommentList.filter(
              (item) => item.id != second.id
            );
            let subd_n = post.comment_total - 1;
            this.parentcomment.comment_total--;
            this.$set(post, "comment_total", subd_n);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width:600px){
  .media_photo_box {
    width: 223px;
  }
}
@media (max-width:600px){
  .media_photo_box {
    width: 100%;
  }
}
.media_photo_box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 150px;

  .photo_box {
    // height: 100%;
    padding-bottom: 10px;
    cursor: pointer;
    
    video,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover + .icon_box {
      opacity: 1;
    }
  }
  .playIcon {
    position: absolute;
    left: 95px;
    top: 45px;
    color: #fff;
    font-size: 35px;
    &:hover {
      cursor: pointer;
    }
  }
  .icon_box {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;

    &:hover {
      opacity: 1;
    }

    li {
      cursor: pointer;
    }
  }
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1024;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgflex {
      position: relative;
      .img {
        max-width: 560px;
      }
      .iconImg {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
.topTitle{
  display: flex;
  justify-content: space-between;
}

::v-deep .comment_and_replay > li {
  margin-bottom: 10px;

  .user_avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .comment_content {
    width: calc(100% - 35px);

    .comment_main {
      > span:first-child {
        color: #50b5ff;
        cursor: pointer;
      }
    }

    .time_icon {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 12px;
        color: #999;
      }

      > ul {
        display: flex;
        font-size: 18px;

        &:hover {
          & > li:first-child {
            opacity: 1;
          }
        }

        > li {
          margin-left: 10px;
          padding: 0 5px;
          border-radius: 50%;
          cursor: pointer;

          &:first-child {
            opacity: 0;
          }

          > i {
            padding: 5px;
            border-radius: 50%;

            &:hover {
              background-color: rgb(204, 229, 245);
              color: #50b5ff;
            }
          }
        }
      }
    }

    .replay_white_box {
      background: #f0f0f0;
      padding: 10px;
      justify-content: flex-end;

      > input {
        width: 100%;
      }

      > div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .second_comment {
    display: flex;
    padding-left: 55px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 60%;
      background-color: #f0f0f0;
      position: absolute;
      top: 50%;
      left: 43px;
      transform: translateY(-50%);
    }

    > span {
      color: #50b5ff;
      cursor: pointer;
    }
  }

  .second_time {
    padding-left: 55px;

    // li hover是显示icon
    &:hover .second_icon {
      opacity: 1;
    }

    span {
      font-size: 12px;
    }

    > ul {
      display: flex;
      opacity: 0;
      font-size: 18px;

      > li {
        margin-left: 10px;
        padding: 0 5px;
        border-radius: 50%;
        cursor: pointer;

        > i {
          padding: 5px;
          border-radius: 50%;

          &:hover {
            background-color: rgb(204, 229, 245);
            color: #50b5ff;
            // opacity: 1;
          }
        }
      }
    }
  }

  .left_padding_white_box {
    background: #f0f0f0;
    padding: 10px;
    margin-left: 55px;
    justify-content: flex-end;

    > input {
      width: 100%;
    }

    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
}

#photo-grid {
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
