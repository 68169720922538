<template>
  <section class="Timeline">
    <div class="iq-card-body p-0">
      <b-row>
        <!-- header 标头 -->
        <b-col lg="4">
          <iq-card>
            <template v-slot:body>
              <div class="row">
                <div class="col-6 border-right">
                  <div class="d-flex flex-column align-items-center">
                    <h5>{{ groupTotal.post_total }}</h5>
                    <!--  <div>Posts</div> -->
                    <div>{{$t('Club.bcc@post')}}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column align-items-center">
                    <h5>{{ groupTotal.member_total }}</h5>

                    <div>{{$t('Club.95c@member')}}</div>
                  </div>
                </div>
              </div>
            </template>
          </iq-card>
          <iq-card class="groupManagerList">
            <!-- headerTitle -->
            <template v-slot:headerTitle>
              <h4 class="card-title">Group manager</h4>
            </template>
            <template v-slot:headerAction>
              <p class="m-0 text-primary" @click="applyAsAdmin">Apply</p>
            </template>
            <div class="row iq-card-body">
              <div class="col-sm-12" v-for="(admin, index) in adminList" :key="index">
                <div class="row mb-4">
                  <div class="col-3">
                    <div class="imgModify">
                      <img class="img_auto" :src="admin.avatar" alt="" />
                    </div>
                  </div>
                  <div class="col-9 d-flex flex-column justify-content-center">
                    <div>{{ admin.nick_name }}</div>
                    <div>{{admin.organization}}</div>
                  </div>
                </div>
              </div>
            </div>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <div id="post-modal-data">
            <AddSocialPost :isShowAddPostModal="userType !== 0" @addPost="addPost" :friendList="friendList"
              :noShowPermission="false"></AddSocialPost>
            <div v-for="post in postList" :key="post.id">
              <social-post :post="post" pageType="group" @commentMsg="commentGroup"></social-post>
            </div>

            <div v-if="loading">
              <el-skeleton animated :count="2">
                <template slot="template">
                  <iq-card body-class="p-0">
                    <div style="padding: 14px;" class="d-flex align-items-center">
                      <el-skeleton-item variant="circle" style="width: 60px; height: 60px;" />
                      <div class="ml-3 d-flex flex-column">
                        <el-skeleton-item variant="text" style="width: 120px;" />
                        <el-skeleton-item variant="text" style="width: 80px;margin-top: 10px;" />
                      </div>
                    </div>
                    <div style="padding: 20px;" class="d-flex justify-content-between mt-5">
                      <el-skeleton-item variant="text" style="width: 60px;" />
                      <el-skeleton-item variant="text" style="width: 60px;" />
                      <el-skeleton-item variant="text" style="width: 60px;" />
                    </div>
                  </iq-card>
                </template>
              </el-skeleton>
            </div>
          </div>
          <p v-if="noMore&&postList.length" class="no_more_common">There's no more content</p>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
  import SocialPost from "@/components/ComComponents/ComPost/SocialPost1";
  import AddSocialPost from "@/components/ComComponents/ComPost/AddSocialPost";
  import {
    mapState
  } from "vuex";
  export default {
    name: "",
    components: {
      SocialPost,
      AddSocialPost,
    },
    data() {
      return {
        adminList: [],
        postList: [],
        friendList: [],
        groupTotal: {},

        page: 1,
        size: 10,
        loading: true,
        noMore: false
      };
    },
    computed: {
      ...mapState({
        userType: state => state.Group.userType,
        visibility: state => state.Group.visibility,
      })
    },
    async created() {
      await this.getGroupInfo()
      this.getPostList();
      this.getGroupMember();
      this.getGroupTotalPost();
      this.$bus.$on('refreshPost', () => {
        this.postList = []
        this.page = 1
        this.getPostList()
      })
    },

    mounted() {
      this.$nextTick(() => {
        this.$on('funInfinitePost', () => {
          this.page++
          this.getPostList()
        })
      })
    },

    beforeDestroy() {
      this.$bus.$off('refreshPost')
    },
    watch: {},
    methods: {
      // 管理员列表
      // 获取俱乐部成员列表
      getGroupMember() {
        this.$http.getGroupMember({
          group_id: this.$route.query.id
        }).then(res => {
          this.friendList = res.data;
          this.adminList = res.data.filter(item => item.status != 1)
        })
      },

      getGroupTotalPost() {
        this.$http.getGroupTotal({
          group_id: this.$route.query.id
        }).then(res => {
          this.groupTotal = res.data;
        })
      },

      // 帖子列表
      getPostList() {
        this.loading = true
        console.log(this.userType);
        if (!this.groupBaseInfo.user_type) return
        this.$http.getGroupPost({
          group_id: this.$route.query.id,
          page: this.page,
          size: this.size
        }).then(res => {
          if (res.status == 200) {
            this.postList = [...this.postList, ...res.data.data]
          }
          if (res.data.current_page == res.data.last_page) {
            this.$emit('fatherMethod');
            this.noMore = true
          }
          setTimeout(() => {
            this.loading = false
          }, 50);
        })
      },

      // 发帖
      addPost() {
        let [post, permission, shareData] = arguments;
        console.log('addhomePost', post);
        this.$http.createPost({
          type: 2, //俱乐部发帖
          type_id: this.$route.query.id, //俱乐部ID
          ...post
        }).then(res => {
          if (res.status == 200) {
            if (post.share_id) {
              shareData.share_total++
            } else {
              this.page = 1
              this.postList = []
              this.getPostList();
            }
          }
        })
      },

      commentGroup(val) {
        console.log(val);
        this.$http.groupPostComment({
          user_post_id: val.postId,
          parent: 0,
          content: val.msg
        }).then(res => {
          if (res.status == 200) {
            this.getPostList();
          }
        })
      },

      // 申请成为管理员
      applyAsAdmin() {
        this.$http.applyBecomeAdmin({
          group_id: this.$route.query.id
        }).then(res => { })
      },

      changePage(page) {
        this.page = page
        this.getPostList()
      },

      getGroupInfo() {
        return new Promise((resolve, reject) => {
          this.$http.getGroupInfo({
            group_id: this.$route.query.id
          }).then(res => {
            this.groupBaseInfo = res.data
            resolve();
          })
        })
      },
    },

  };

</script>
<style src='' lang='scss' scoped>
  .Timeline {
    .groupManagerList {
      .imgModify {
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-radius: 50%;
        border: 2px solid #e7e7e7;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
</style>